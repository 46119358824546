// src/App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Global, css } from '@emotion/react';
import SignupSection from './components/SignupSection';
import PaymentPage from './components/PaymentPage';
import SuccessPage from './components/SuccessPage';
import { AuthProvider, AuthContext } from './components/AuthContext';

const globalStyles = css`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap');
  body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    background-color: white;
  }
`;

const AppContent = () => {
    const { user } = useContext(AuthContext);

    return (
        <Router>
            <Routes>
                <Route path="/" element={user ? <SuccessPage /> : <SignupSection />} />
                <Route path="/payment" element={<PaymentPage />} />
                <Route path="/success" element={<SuccessPage />} />
            </Routes>
        </Router>
    );
};

const App = () => (
  <>
    <Global styles={globalStyles} />
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  </>
);

export default App;