import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import planImage from '../images/image.png';
import logo from '../images/logo.png';
import { AuthContext } from './AuthContext';
import { SliderSwitch } from "./SliderSwitch";

// Add the EmailButton styled component
const EmailButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 12px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  background-color: #FF9400;
  color: white;
  border: none;
`;
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-position: top left, bottom left;
  background-repeat: no-repeat;
  background-size: 30%, 30%;
  background-color: #f9f9f9;
`;

const Logo = styled.img`
  height: 100px;
  left: 50%;
  padding: 20px;
`;

const PaymentButtons = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  padding: 30px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
  width: 30%;
  min-height: 50vh;

  @media (max-width: 1200px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    width: 70%;
    min-height: 70vh;
  }
`;

const Description = styled.p`
  text-align: center;
  margin: 0;
`;

const Price = styled.p`
  font-size: 36px;
  font-weight: bold;
  text-align: right;
  margin:0;
`;

const ListItem = styled.li`
  font-size: 18px;
  margin: 0;
  list-style: none;
  
  ::before {
    content: '✔';
    display: inline-block;
    margin-right: 5px;
    color: #FF9400; // Set the checkmark color
  }
`;

const Button = styled.button`
  border-radius: 12px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
`;

const StripeButton = styled(Button)`
  background-color: white;
  color: #FE9400;
  font-weight: bold;
  border: 2px solid;
  border-color: #FE9400;
  align-items: center;
  justify-content: center;
`;

const PaypalButton = styled(Button)`
  background-color: #00457C;
  color: white;
  font-weight: bold;
  border: none;
  &:before {
    content: 'Pay With Pay';
    color: #FFFFFF;
  }
  &:after {
    content: 'Pal';
    color: #1298DB;
  }
`;

const PlanImage = styled.img`
  max-width: 40%;
  border-radius: 12px;
`;

const PaymentPage = () => {
const [paymentPeriod, setPaymentPeriod] = useState('monthly');
const [price, setPrice] = useState(49); // Set your monthly price here
const { user } = useContext(AuthContext);

const handleStripePayment = () => {
  if (paymentPeriod === 'monthly') {
    window.location.href = "https://buy.stripe.com/4gweXXgZEbHxaQ0288";
  }else{
    window.location.href = "https://buy.stripe.com/00g4jjcJoh1R6zKcMO";
  }
  
};

const handlePaypalPayment = () => {
  if (paymentPeriod === 'monthly') {
    window.location.href = "https://buy.stripe.com/4gweXXgZEbHxaQ0288";
  }else{
    window.location.href = "https://buy.stripe.com/00g4jjcJoh1R6zKcMO";
  }
};

const PackageTitle = styled.h3`
  font-size: 28px;
  color: #FF9400;
  font-weight: bold;
  text-align: center;
  margin: 0;
`;

const ImageAndPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const handleToggleSwitchChange = (isYearly) => {
  if (isYearly) {
    setPaymentPeriod('yearly');
    setPrice(490); // Set your yearly price here
  } else {
    setPaymentPeriod('monthly');
    setPrice(49); // Set your monthly price back here
  }
};

  return (
    <Wrapper> 
      {user && <EmailButton>{user.email}</EmailButton>}
      <Logo src={logo} alt="Profit Pilot Logo" />
      <Card>
        <PackageTitle>Retail Revolution Plan</PackageTitle>
        <Description>We only have one plan, it comes with all you need.</Description>
        <ImageAndPriceWrapper>
          <PlanImage src={planImage} alt="shopping cart image with lots of things in it" />
          <div>
            <SliderSwitch onChange={handleToggleSwitchChange}></SliderSwitch>
            <Price>{price}$</Price>
          </div>
        </ImageAndPriceWrapper>
        <ul>
          <ListItem>Scout App</ListItem>
          <ListItem>Keepa Graphs</ListItem>
          <ListItem>Web Dashboard</ListItem>
          <ListItem>Custom Repricer</ListItem>
          <ListItem>AI Insights</ListItem>
          <ListItem>Gated Check</ListItem>
          <ListItem>Hazmat Check</ListItem>
          <ListItem>IP/Buybox Check</ListItem>
        </ul>
      </Card>
      <PaymentButtons>
        <StripeButton onClick={handleStripePayment}> Pay with Card </StripeButton>
        <PaypalButton onClick={handlePaypalPayment}></PaypalButton>
      </PaymentButtons>
    </Wrapper>
  );
};

export default PaymentPage;