// src/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { onAuthStateChanged, getIdTokenResult, signOut } from 'firebase/auth';
import { auth } from './firebase';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const tokenResult = await getIdTokenResult(currentUser);
        setUser({ ...currentUser, tokenExpirationTime: new Date(tokenResult.expirationTime) });
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!user || !user.tokenExpirationTime) return;

    const timeToExpiration = user.tokenExpirationTime.getTime() - (new Date()).getTime();
    const tokenRefreshTimeout = setTimeout(async () => {
      if (!auth.currentUser) return;
      const tokenResult = await getIdTokenResult(auth.currentUser, true);
      setUser({ ...auth.currentUser, tokenExpirationTime: new Date(tokenResult.expirationTime) });
    }, timeToExpiration);

    return () => clearTimeout(tokenRefreshTimeout);
  }, [user]);

  const logout = async () => {
    await signOut(auth);
  };

  return (
    <AuthContext.Provider value={{ user, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };