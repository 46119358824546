// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDm5BKUD1KIs2mBl0yxa_iPvuirnhlncC8",
  authDomain: "profit-pilot-countdown.firebaseapp.com",
  projectId: "profit-pilot-countdown",
  storageBucket: "profit-pilot-countdown.appspot.com",
  messagingSenderId: "42321855425",
  appId: "1:42321855425:web:ce700e99f48dd244556540",
  measurementId: "G-90RW75BL87"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };