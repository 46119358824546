import React, { useState } from "react";
import styled from "@emotion/styled";

const SliderSwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  border: 2px solid #FE9400;
  border-radius: 5px;
  overflow: hidden;
`;

const PlanButton = styled.button`
  background-color: ${props => (props.isActive ? "#FE9400" : "#ffffff")};
  color: ${props => (props.isActive ? "white" : "black")};
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ccc;
  }
`;

const SliderSwitch = (props) => {
  const [plan, setPlan] = useState("monthly");

  const handlePlanChange = (newPlan) => {
    setPlan(newPlan);
    if (props.onChange) {
      props.onChange(newPlan === "yearly");
    }
  };

  return (
    <SliderSwitchWrapper>
      <ButtonWrapper>
        <PlanButton isActive={plan === "monthly"} onClick={() => handlePlanChange("monthly")}>
          Monthly
        </PlanButton>
        <PlanButton isActive={plan === "yearly"} onClick={() => handlePlanChange("yearly")}>
          Yearly
        </PlanButton>
      </ButtonWrapper>
    </SliderSwitchWrapper>
  );
};

export { SliderSwitch };