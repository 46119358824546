// src/SuccessPage.js
import React from 'react';
import styled from '@emotion/styled';
import logo from '../images/logo.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-height: 100vh;
  text-align: center;
  background-color: #f9f9f9;
`;

const Button = styled.button`
  border-radius: 12px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
`;

const LetsGoButton = styled(Button)`
  background-color: #FF9400;
  color: white;
  border: #FF9400;
  font-size: 16px; 
`;

const SuccessPage = () => {
    const handleLetsGoClick = () => {
        // Redirect to the main website
        window.location.href = "https://profitpilot.app";
    };

    return (
        <Wrapper>
            <img src={logo} alt="profit pilot logo" style={{ maxWidth: '30%', marginBottom: '3%', marginTop: '5%' }} />
            <h2>You're officially in the pilot's seat - welcome to Profit Pilot!</h2>
            <p>Ready to take a look?</p>
            <LetsGoButton onClick={handleLetsGoClick}>Let's Go!</LetsGoButton>
        </Wrapper>
    );
};

export default SuccessPage;
