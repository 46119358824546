import React, { useState } from 'react';
import styled from '@emotion/styled';
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, provider } from './firebase';
import logo from '../images/logo.png';
import googleLogo from '../images/google-logo.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  background-color: #f9f9f9;
  min-height: 100vh;
  padding: 15px 0; // Add top and bottom padding
  justify-content: center; // Add this line to center the content vertically
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
  border-radius: 8px;
  width: 100%;
  max-width: 30%;

  @media (max-width: 800px) {
    max-width: 80%; // Adjust the max-width for smaller screens
  }
`;

const Button = styled.button`
  border-radius: 12px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
`;

const SignUpButton = styled(Button)`
  background-color: #FF9400;
  color: white;
  border: none;
`;

const GoogleButton = styled(Button)`
  background-color: white;
  color: #4285F4;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #4285F4;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const InputField = styled.input`
  border-radius: 12px;
  border: 2px solid #ccc; // Change the border color to greyish
  padding: 8px 16px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
`;


const SignupSection = () => {
    const [fullName, setFullName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleGoogleSignUp = async () => {
        try {
            await signInWithPopup(auth, provider);
            // Redirect to payment page
            window.location.href = "/payment";
        } catch (error) {
            console.error(error);
            setErrorMessage('Google signup failed');
        }
    };

    const handleSignUpWithEmail = async (email, password) => {
        if (!email || !password || !fullName || !username) {
            setErrorMessage('Please fill in all required fields');
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }

        try {
            await createUserWithEmailAndPassword(auth, email, password);
            // Redirect to payment page
            window.location.href = "/payment";
        } catch (error) {
            console.error(error);
            setErrorMessage('Signup failed');
        }
    };

    return (
        <Wrapper>
          <img src={logo} alt="profit pilot logo" style={{maxWidth: '30%'}} />
          <h2>Sign Up for Profit Pilot</h2>
          <p>Create an account to continue</p>
          <FormWrapper>
            <InputField
              type="text"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <InputField
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <InputField
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputField
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputField
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <SignUpButton onClick={() => handleSignUpWithEmail(email, password)}>
              Sign Up
            </SignUpButton>
          </FormWrapper>
          <p style={{ color: 'red' }}>{errorMessage}</p>
          <p>or sign up with Google</p>
          <GoogleButton onClick={handleGoogleSignUp}>
            <img src={googleLogo} alt="Google logo" width="20" />
            Sign Up with Google
          </GoogleButton>
        </Wrapper>
      );
    };
    
    export default SignupSection;